body {
  margin: 0;
  background:#E9EDF5; 
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html, p, h1, h2, h3, h4, h5, h6, menu, li, ul, button, ui, a, item, link, .accordion, .title {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
}

.accordion {
  padding: 1.3em !important;
}
h6 {
  font-size:2.7em !important;
  margin: 2px !important;
  line-height:1em !important;
}
#plan-top{
  background-color: rgb(238, 236, 245) !important;
  background-size: cover !important;
  border:none !important;
  /*background-image: url(/subscription-bg-gray.jpg) !important;*/
}
.ui.segment, .accordion {
  border-radius: 14px !important;
  box-shadow: 0px 6px 14px rgba(0,0,0,0.1) !important;
  border:none !important;
}
.button {
  border-radius: 7px !important;
  
}
.blue.button {
  box-shadow: 0px 3px 6px rgba(0,0,0,0.2) !important;
}
.blue {
background-color: #5931EA !important;

}
.padded{
  padding: 2.5em !important;
}
a {
  color: #5931EA !important;
  font-weight: 600 !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

i.green.icon {
  color: #198846 !important;
}
